import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { connectWallet, deployIOU, signAndCreate } from "./walletAction";

export interface WalletState {
  chainId?: number;
  wallet?: string;
  contract?: string;
  status: "idle" | "loading" | "connected" | "failed" | "signed" | "deployed";
}

const contractLocal = localStorage.getItem("contract")
  ? JSON.parse(localStorage.getItem("contract") || "")
  : null;

const initialState: WalletState = {
  status: "idle",
  contract: contractLocal,
};

export const connect = createAsyncThunk("wallet/Connect", () => {
  const response = connectWallet();
  return response;
});

export const sign = createAsyncThunk(
  "wallet/Sign",
  ({ units, timeStamp }: { units: number; timeStamp: Date }) => {
    const response = signAndCreate(units, timeStamp);
    return response;
  }
);

export const deploy = createAsyncThunk("wallet/Deploy", () => {
  const response = deployIOU();
  return response;
});

export const WalletSlice = createSlice({
  name: "myWallet",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(connect.pending, (state) => {
        state.status = "loading";
      })
      .addCase(connect.fulfilled, (state, action) => {
        state.status = "connected";
        const { walletAddress } = action.payload;
        state.wallet = walletAddress;
      })
      .addCase(connect.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(sign.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sign.fulfilled, (state, action) => {
        state.status = "signed";
        console.log(action.payload);
      })
      .addCase(sign.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(deploy.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deploy.fulfilled, (state, action) => {
        state.status = "deployed";
        localStorage.setItem("contract", JSON.stringify(action.payload));
        state.contract = action.payload;
      })
      .addCase(deploy.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default WalletSlice.reducer;
